/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



.toast-warning {
    background-color: #DC3545 !important;
}
.toast-info {
    background-color: #fdc21f !important;
}